<template>
  <!-- Hero content: will be in the middle -->
  <section class="section">
    <div class="container">
      <div class="columns is-multiline box" style="background-color:#f0f0f0">
        <div class="column is-12">
          hanya copy url saja, ga perlu isi nama dll
        </div>
        <div class="column is-12">
          <b-input v-model="autoURL"></b-input>
          App URL: (untuk redirect setelah thank you page)
          <b-input v-model="redirectURL"></b-input>
          <button v-if="autoURL" @click="generateRandomNumber()">
            ganti random number
          </button>
          <b-field>
            <b-switch
              v-model="preview"
              true-value="preview"
              false-value="not preview"
            >
              {{ preview }}
            </b-switch>
          </b-field>
          <span v-if="preview">data tidak disimpan</span>
          <span v-else>data akan disimpan</span>
        </div>
        <span v-if="autoURL">
          {{ generateURL }}
        </span>
      </div>
      <br />
      <hr />
      <br />
      <div class="columns">
        <div class="column is-2">Nit</div>
        <div class="column is-10">
          <b-input v-model="iframe.nit"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          App URL (untuk mengirim data dan memberikan status sudah complete)
        </div>
        <div class="column is-10">
          <b-input v-model="iframe.app_url"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">ID Tes</div>
        <div class="column is-10">
          <b-input v-model="iframe.id_tes"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">Name</div>
        <div class="column is-10">
          <b-input v-model="iframe.name"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">Cid</div>
        <div class="column is-10">
          <b-input v-model="iframe.cid"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">Uid</div>
        <div class="column is-10">
          <b-input v-model="iframe.uid"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">email</div>
        <div class="column is-10">
          <b-input v-model="iframe.email"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">Url</div>
        <div class="column is-10">
          <b-input v-model="iframe.url"></b-input>
        </div>
      </div>
      <!-- <div class="columns">
        <div class="column is-2">Token</div>
        <div class="column is-10">
          <b-input type="textarea" v-model="iframe.token"></b-input>
        </div>
      </div> -->
      <div class="columns">
        <div class="column is-2">Test Id</div>
        <div class="column is-10">
          <b-input type="textarea" v-model="iframe.test_id"></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">URL</div>
        <div class="column is-10">
          <b-input type="textarea" v-model="url" readonly></b-input>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <b-field grouped position="is-right">
            <b-button type="is-hcc" @click="submit()">Access iframe</b-button>
          </b-field>
        </div>
      </div>
      <div class="columns" v-if="!isIframeLoading">
        <iframe
          :src="url"
          width="100%"
          style="border:1px solid lightgrey;"
          sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups allow-modals"
          class="frame"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      autoURL: null,
      iframe: [],
      name: null,
      email: null,
      token: null,
      url: null,
      isIframeLoading: true,
      randomNumber: null,
      preview: 'preview',
      redirectURL: 'https://www.google.com',
    }
  },
  methods: {
    async submit() {
      const response = await this.$store.dispatch('goToIframe', this.iframe)
      this.url = response.data
      this.isIframeLoading = false
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    generateRandomNumber() {
      this.randomNumber = Math.floor(Math.random() * 9999999999) + 100000
    },
  },
  computed: {
    // a computed getter
    generateURL() {
      let redirectURL2 = null
      if (this.redirectURL == null || this.redirectURL == '') {
        redirectURL2 = this.randomNumber
      } else {
        redirectURL2 = this.redirectURL
      }
      return `${this.autoURL}?app_url=${redirectURL2}&id_tes=${this.randomNumber}&nit=${this.randomNumber}&nama=${this.randomNumber}&email=${this.randomNumber}@example.com&cid=${this.randomNumber}&uid=${this.randomNumber}&preview=${this.preview}`
    },
  },
  created() {
    this.generateRandomNumber()
    this.generateURL()
    document.addEventListener('keyup', function(e) {
      var keyCode = e.keyCode ? e.keyCode : e.which
      if (keyCode == 44) {
        stopPrntScr()
      }
    })
    function stopPrntScr() {
      var inpFld = document.createElement('input')
      inpFld.setAttribute('value', '.')
      inpFld.setAttribute('width', '0')
      inpFld.style.height = '0px'
      inpFld.style.width = '0px'
      inpFld.style.border = '0px'
      document.body.appendChild(inpFld)
      inpFld.select()
      document.execCommand('copy')
      inpFld.remove(inpFld)
    }
  },
}
</script>

<style lang="scss" scoped>
.login {
  border: 1px solid #d6dee3;
}
.frame {
  height: 800px;
}
.login-header {
  border-bottom: 1px solid #f2f5f9;
  padding: 30px;
}
.login-title {
  color: #333d5d;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.login-subtitle {
  color: #b9bbc6;
}
.login-body {
  padding: 30px;
}
.form-group {
  margin-bottom: 1.7rem;
}
</style>
